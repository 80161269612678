import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Access`}</h2>
    <h3>{`How can I get access to the Fidel sandbox?`}</h3>
    <p>{`You can simply go to our website  `}<strong><a href="https://dashboard.fidel.uk">{`Fidel API - Dashboard`}</a></strong>{` and register, no activation is required.`}</p>
    <h2>{`Accounts/ users`}</h2>
    <h3>{`How can I add multiple users on my side to the Fidel dashboard?`}</h3>
    <p>{`We recommend that only one user on your side creates a Fidel account and the rest of the users who need access get 'invited' through the dashboard to the same account. This will help you promote that account to Production as we can only promote one account per client per country to production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      